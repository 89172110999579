<template>
  <div class="container mt-3">
    <div class="row">
      <h2>{{ $t('cert.certDetail') }} / {{ cert.name }}</h2>
    </div>
    <div class="row">
      <router-link
          class="arrow-back"
          :to="{ name: 'Dashboard' }"
          title="Zpět na nástěnku"
      >
        <i class="bi bi-arrow-left"></i>{{ $t('general.backToDashboard') }}
      </router-link
      >
    </div>
    <div class="row mt-4">
      <div class="col-12 col-md-6 mb-3">
        <div class="card">
          <div class="card-header d-flex">
            {{ $t('cert.certInfo') }}
          </div>
          <div class="card-body">
            <Skeletor v-if="loading"/>
            <div v-if="!loading">
              <div class="row">
                <div class="col-6">{{ $t('cert.certName') }}:</div>
                <div class="col-6">{{ cert.name }}</div>
              </div>
              <div class="row">
                <div class="col-6">{{ $t('cert.certCreatedDate') }}:</div>
                <div class="col-6">{{ cert.generated_at ? formatDate(cert.generated_at) : "---" }}</div>
              </div>
              <div class="row">
                <div class="col-6">{{ $t('file.fileName') }}:</div>
                <div class="col-6">{{ cert.file_name ?? "---" }}</div>
              </div>
              <div class="d-flex justify-content-end pt-3">
                <button class="delete-button text-decoration-underline px-1 fw-600" @click="deleteCert()">
                  {{ $t('cert.deleteCert') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6 mb-3">
        <div class="card">
          <div class="card-header">{{ $t('file.file') }}</div>
          <div class="card-body">
            <Skeletor v-if="loading"/>
            <div v-if="!loading && cert.file_uuid">
              <!--                            <div class="row mb-3" v-if="file && file.cert">
                                              <div class="col-6">{{ $t('cert.certWasGenerated') }}:</div>
                                              <div class="col-6">{{ formatDate(file?.cert.created_at) }}</div>
                                          </div>-->
              <div class="row">
                <!--                <div class="col-6 ms-auto">
                                  <button @click="generateCert()" class="btn btn-primary w-100" :disabled="generatingCert">
                                    {{ generatingCert ? $t('cert.generatingCert') : $t('cert.generateCert') }}
                                  </button>
                                </div>-->
                <div class="col-6 ms-auto">
                  <button @click="downloadFile()" class="btn btn-primary w-100">{{ $t('general.download') }}</button>
                </div>
                <div class="col-6 ms-auto">
                  <router-link
                      class="btn btn-primary w-100"
                      :to="{ name: 'FileDetail', params: { uuid: cert.file_uuid } }"
                      title="{{ $t('file.showFile') }}"
                  >{{ $t('general.preview') }}
                  </router-link
                  >
                </div>
                <!--                </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--      <div class="col-12 col-md-6">
              <CertDownloadComponent></CertDownloadComponent>
              <div v-if="cert.file_uuid" class="card mt-3">
                <div class="card-header d-flex">
                  {{ $t('file.file') }}
                </div>
                <div class="card-body">
                  <Skeletor v-if="loading"/>
                  <div v-if="!loading">
                    <div class="row">
                      <div class="col-12">
                        <router-link
                            class="btn btn-primary w-100"
                            :to="{ name: 'FileDetail', params: { uuid: cert.file_uuid } }"
                            title="Zobrazení souboru"
                        >{{ $t('general.show') }}
                        </router-link
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>--></div>
    <div class="row mt-4 mb-5">
      <div class="col-12">
        <div class="card">
          <div v-if="!cert.generated_at" class="card-body d-flex align-items-center">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div><span class="ms-3">{{ $t('cert.waitingUntilGenerated') }}</span>
          </div>
          <div v-else>
            <div class="card-header d-flex align-items-center">
              {{ $t('general.preview') }}
              <div class="ms-auto">
                <button class="btn btn-primary" @click="downloadCert()">
                  {{ $t('cert.download') }}
                </button>
              </div>
            </div>
            <div class="card-body">
              <Skeletor v-if="loading"/>
              <vue-pdf-embed v-if="pdfSrc" ref="pdfRef" :source="pdfSrc"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/http';
import VuePdfEmbed from "vue-pdf-embed";

export default {
  components: {VuePdfEmbed},
  mounted() {
    this.getCertDetail();
  },

  data() {
    return {
      cert: [],
      loading: true,
      pdfSrc: null,
    };
  },

  methods: {
    getCertDetail() {
      let self = this;
      http.get('/certs/' + self.$route.params.uuid + '/detail').then((response) => {
        self.cert = response.data.cert;
        self.loading = false;
        self.getPdfSrc();
      });
    },

    deleteCert() {
      let self = this;

      let deleteFile = false;

      self.$swal
          .fire({
            title: self.$t('cert.confirmDelete'),
            icon: 'warning',
            input: self.cert?.file_uuid ? 'checkbox' : false,
            inputValue: 1,
            inputPlaceholder: self.$t('cert.fileDeleteToo'),
            showCancelButton: true,
            confirmButtonColor: '#175cff',
            cancelButtonColor: '#d33',
            confirmButtonText: self.$t('general.yes'),
            cancelButtonText: self.$t('general.no'),
          })
          .then((result) => {
            if (result.isConfirmed) {
              deleteFile = !result.value;

              http
                  .post(
                      '/certs/' +
                      self.$route.params.uuid + '/delete?deleteOnlyCert=' +
                      deleteFile
                  )
                  .then((response) => {
                    self.$router.push({name: 'Dashboard'});
                  });
            }
          });
    },

    downloadFile() {
      let self = this;
      http
          .get('/files/' + self.cert.file_uuid + '/view?download=true', {
            responseType: 'blob',
          })
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', self.cert.file_name); // Nastav název a příponu souboru

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
    },

    getPdfSrc() {
      this.pdfSrc = 'data:application/pdf;base64,' + this.cert.pdf_base64;
    },

    downloadCert() {
      let self = this;
      http
          .post('/certs/' + self.cert.uuid + '/download', {
            responseType: 'blob',
          })
          .then((response) => {
            var fileURL = `data:application/pdf;base64,${response.data}`;
            var fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'Certificate.pdf');
            document.body.appendChild(fileLink);

            fileLink.click();
            fileLink.remove();
          });
    },

    formatDate(date) {
      let d = new Date(date);
      return (
          ('00' + d.getDate()).slice(-2) +
          '. ' +
          ('00' + (d.getMonth() + 1)).slice(-2) +
          '. ' +
          d.getFullYear() +
          ' ' +
          ('00' + d.getHours()).slice(-2) +
          ':' +
          ('00' + d.getMinutes()).slice(-2) +
          ':' +
          ('00' + d.getSeconds()).slice(-2)
      );
    },
  },
};
</script>
